import Vue from 'vue'
import {router} from './router'
import ElementUI from 'element-ui'
import App from './App.vue'
import http from '@/utils/http'
import { message } from '@/utils/resetMessage';
import locale from 'element-ui/lib/locale/lang/en'
// import {dateFormat} from '@/utils/filter'
import * as filters from '@/utils/filter'


import '@/assets/style/theme/index.css'
import '@/assets/style/normalize.css'
import '@/assets/style/main.less'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.use(http)
Vue.use(ElementUI,{locale})
Vue.use(router)
Vue.prototype.$message = message
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
