<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    // this.$nextTick(() => {
    //   const _this = this;
    //   this.bodyScale();
    //   window.onresize = function () {
    //     _this.bodyScale();
    //   };
    // });
  },
  methods: {
    // bodyScale() {
    //   let devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
    //   if (devicewidth < 1000) {
    //     let scale = devicewidth / 550; // 分母——设计稿的尺寸
    //     document.body.style.zoom = scale; //放大缩小相应倍数
    //   } else {
    //     let scale = 1;
    //     document.body.style.zoom = scale;
    //   }
    // },
  },
};
</script>

<style>
#app {
  font-family: Roboto-Bold, Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
