import DateFormat from 'dateformat'

export const dateFormat = (value, format = 'yyyy-mm-dd HH:MM:ss') => {
    if(value === null || value === '') return
    value = Number(value) > 100000000000 ? value : value * 1000
     return DateFormat(value, format)
}
export const dateFormat2 = (value, format = 'yyyy-mm-dd') => {
    if(value === null || value === '') return
    value = Number(value) > 100000000000 ? value : value * 1000
     return DateFormat(value, format)
}
export const formatNumber =(num,fixedNum = 2) => {
    if(isNaN(num)) return num;
    num=Number(num).toFixed(fixedNum);
    var reg = /\B(?=(\d{3})+(?!\d))/g;
    return (num + '').replace(reg, ',');
  }
export const formatNumber2 =(num,fixedNum = 0) => {
    if(isNaN(num)) return num;
    num=Number(num).toFixed(fixedNum);
    var reg = /\B(?=(\d{3})+(?!\d))/g;
    return (num + '').replace(reg, ',');
  }
  