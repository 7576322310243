import Vue from 'vue'
import VueRouter from "vue-router";
Vue.use(VueRouter)
const Main = () => import('@/views/Main')
const Login = () => import('@/views/Login')
const Register = () => import('@/views/Register')
const routes=[
  {
    path: "/login",
    component: Login
  },
  {
    path: "/register",
    component: Register
  },

  { path: "/", redirect: "/login" },
  {
    path: "/main",
    component: Main,
    redirect:'/home',
    children:[
      {
        path: "/home",
        meta:'Home > Home Page Overview',
        component: () =>import("@/views/home"),
      },
      {
        path: "/hostInvitationList",
        name:'hostInvitationList',
        meta:'MemberManagement > Host Invitation List',
        component: () =>import("@/views/memberManagement/hostInvitationList"),
      },
      {
        path: "/hostList",
        name:'hostList',
        meta:'MemberManagement > Host List',
        component: () =>import("@/views/memberManagement/hostList"),
      },
      {
        path: "/agencyList",
        name:'agencyList',
        meta:'MemberManagement > Sub-agency List',
        component: () =>import("@/views/memberManagement/agencyList"),
      },
      // {
      //   path: "/hostData",
      //   name:'hostData',
      //   meta:'memberManagement > Host List > Host Data',
      //   component: () =>import("@/views/memberManagement/hostData"),
      // },
      {
        path: "/dailyData",
        name:'dailyData',
        meta:'Daily Report > Host Daily Data',
        component: () =>import("@/views/dailyReport/dailyData"),
      },
      {
        path: "/agencyData",
        name:'agencyData',
        meta:'Daily Report > Sub-agency Daily data',
        component: () =>import("@/views/dailyReport/agencyData"),
      },
      {
        path: "/union",
        name:'union',
        meta:'Settlement Management > Union Billing',
        component: () =>import("@/views/settlementManagement/union"),
      },
      {
        path: "/withdrawal",
        name:'withdrawal',
        meta:'Settlement Management > Withdrawal',
        component: () =>import("@/views/settlementManagement/withdrawal"),
      },
      {
        path: "/messageList",
        name:'messageList',
        meta:'Team Message',
        component: () =>import("@/views/messageList"),
      },
    ]
    
  },
]
const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, form, next) => {
  next()
})
export { router }