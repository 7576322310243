import { message } from './resetMessage';
import axios from 'axios'
import config from './config'

axios.defaults.baseURL = config.baseURL
axios.defaults.timeout = 100000

const toType = obj => {
  return {}.toString
    .call(obj)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase()
}

const filterNull = o => {
  if (toType(o) !== 'object' || o.nofilter) return o
  for (var key in o) {
    if (o[key] === null) {
      delete o[key]
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim()
      if (o[key].length === 0) {
        delete o[key]
      }
    }
  }
  return o
}

const http = {}

http.base = (type, path, params,blob, headers, callback) => {
  console.log(2222);
  const token = localStorage.getItem('token') || ''
  let config = {
    headers: Object.assign(
      {},
      headers,
      { unionToken: token },
    // { "Content-Type": "multipart/form-data" }
    ),
    url: path,
    method: type,
    responseType:blob
  }
  if(['undefined', ''].includes(token)) {
    delete config.headers.unionToken
  }
  if(blob !== 'blob'){
    delete config.responseType
  }
  if (type !== 'get') {
    config['data'] = params
  } else {
    params = filterNull(params) || {}
    config['params'] = params
  }
  return new Promise((resolve, reject) => {
    axios(config).then((res) => {
      console.log(res.data)
      if(blob === 'blob'){
        resolve(res)
        return
      }
      if (res.data.code != 0) {
        message.error(res.data.msg)
        if ([101004, 101001].includes(res.data.code)) {
          localStorage.setItem('token', '')
          localStorage.setItem('userName', '')
          setTimeout(() => {
            if(location.pathname != '/login') {
              location.href = '/login'
            }
          }, 1500)
        }
        reject(res.data || { code: -1, msg: 'Service exception, please try again later' })
      } else {
        resolve(res.data)
      }
      callback && callback(res.data) // 欢迎使用同步 $get.('/api',params，res=>{})
    })
    .catch(() => {
      console.log('时间:' + new Date() + ',接口异常: 错误代码,接口地址:' + path);
      message.error('Service exception, please try again later')
      // message({
      //   type:'error',
      //   dangerouslyUseHTMLString: true,
      //   message:'error</br>服务异常，请1111稍后再试',
      //   duration:0,
        
      // })
    })
  })
}

http.install = Vue => {
  ['get', 'post', 'put', 'delete'].forEach(
    h =>
      (Vue.prototype['$' + h] = (url, params, headers, callback) =>
        http.base(h, url, params, headers, callback))
  )
}
export default http
