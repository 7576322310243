
export const isDev = /^(localhost|192|204|dev)/.test(location.hostname)
    ? '1'
    : /^(t-union-console)/.test(location.hostname)
    ? '2'
    : '3'
//http://192.168.1.211:9040
//http://t-api-union.woweds.com
//1测试地址 2预发布地址 3正式地址
console.log(isDev, '当前环境')
console.log(location.hostname)
// export const baseURL =isDev === '1'? 'http://t-api-union.woweds.com': isDev === '2'? 'http://t-api-union.woweds.com': 'https://wow.woweds.com/labor_union'
export const baseURL =isDev === '1'? 'https://t-uapi.woweds.com': isDev === '2'? 'https://t-uapi.woweds.com': 'https://uapi.woweds.com'
console.log(baseURL, '当前api地址')
export default {
    isDev,
    baseURL,
  }

